<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>
        <b>Bem-vindo!</b>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col
          class="text-center"
        >
          Entre com seu usuário e senha para continuar
        </v-col>
      </v-row>
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-form @submit="login">
            <v-row class="pa-4">
              <v-col>
                <validation-observer ref="observer">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="username"
                  >
                    <v-text-field
                      v-model="form.username"
                      hide-details="auto"
                      label="Usuário"
                      class="mb-4"
                      filled
                      autocomplete="nope"
                      :error-messages="errors"
                      prepend-icon="mdi-account-circle"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    vid="password"
                  >
                    <v-text-field
                      v-model="form.password"
                      hide-details="auto"
                      type="Password"
                      label="Senha"
                      filled
                      :error-messages="errors"
                      prepend-icon="mdi-lock"
                    />
                  </validation-provider>
                </validation-observer>
              </v-col>
            </v-row>
            <v-row class="mt-4 px-4 pt-0 pb-1">
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  :loading="loadingLogin"
                  :disabled="loading"
                  type="submit"
                >
                  {{ !loading ? 'Responder Pesquisa' : 'Carregando...' }}
                  <v-icon right v-text="'mdi-arrow-right'" />
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapGetters } from 'vuex'
  import authApi from '@/api/auth'

  export default {

    components: {

    },

    data: () => ({
      loadingLogin: false,
      form: {
        username: null,
        password: null,
      },
    }),

    computed: {
      ...mapState({
        loading: state => state.answerSurvey.loading,
      }),
      ...mapGetters({
        currentQuestion: 'answerSurvey/currentQuestion',
      })
    },

    methods: {
      ...mapMutations({
        setToken: 'contact/SET_TOKEN',
        setContact: 'contact/SET_CONTACT',
        setAnswers: 'answerSurvey/SET_ANSWERS',
      }),
      ...mapMutations({
        setIndexQuestion: 'answerSurvey/SET_INDEX_QUESTION',
      }),

      async login (event) {
        try {
          event.preventDefault()
          this.loadingLogin = true

          const response = await authApi.loginAnswerSurvey(this.$route.params.surveyId, this.form)

          this.setToken(response.data.token)
          this.setContact(response.data.contact)
          this.setAnswers(response.data.answers)

          this.setIndexQuestion(0)
          this.$router.push(
            `/responder-pesquisa/${this.$route.params.surveyId}/perguntas`
          )
        } catch (e) {
          if (e.response && e.response.status === 422) {
            return this.$refs.observer.setErrors(
              this.$apiError.fieldsValidation(e),
            )
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingLogin = false
        }
      },
    },

  }
</script>
